.MuiBottomNavigation-root {
    height: 50px!important;
    border-radius: 12px !important;
}
.MuiPaper-root {
    border-radius: 12px!important;
}
.Mui-selected {
    color: #ec6608!important;
    font-size: 0.75rem!important;
}
.Mui-selected img {
   filter: brightness(0) saturate(100%) invert(51%) sepia(59%) saturate(6851%) hue-rotate(13deg) brightness(108%) contrast(94%);
}